import { defineAsyncComponent } from 'vue';

export const addGlobalComponents = app => {
    return app
        .component('TablepressTable', defineAsyncComponent(() =>
            import('./plugins/tablepress/tablepress-table.vue'),
        ))
        .component('WatsonOrganismContent', defineAsyncComponent(() =>
            import('./organisms/content/watson-organism-content.vue'),
        ))
        .component('WatsonGlobalContentColumnCheckmarks', defineAsyncComponent(() =>
            import('./organisms/content-column-checkmarks/watson-organism-content-column-checkmarks.vue'),
        ))
        .component('WatsonGlobalContactForm', defineAsyncComponent(() =>
            import('./organisms/contact-form/watson-organism-contact-form.vue'),
        ))
        .component('WatsonGlobalHeaderText', defineAsyncComponent(() =>
            import('./organisms/header-text/watson-organism-header-text.vue'),
        ))
        .component('WatsonGlobalIconLinkBar', defineAsyncComponent(() =>
            import('./organisms/icon-link-bar/watson-organism-icon-link-bar.vue'),
        ))
        .component('WatsonGlobalJumpLinksHorizontal', defineAsyncComponent(() =>
            import('./organisms/jump-links-horizontal/watson-organism-jump-links-horizontal.vue'),
        ))
        .component('WatsonGlobalJumpLinksVertical', defineAsyncComponent(() =>
            import('./organisms/jump-links-vertical/watson-organism-jump-links-vertical.vue'),
        ))
        .component('WordpressColumns', defineAsyncComponent(() =>
            import('./wordpress/columns/wordpress-columns.vue'),
        ))
        .component('WordpressColumn', defineAsyncComponent(() =>
            import('./wordpress/column/wordpress-column.vue'),
        ))
        .component('WordpressGroup', defineAsyncComponent(() =>
            import('./wordpress/group/wordpress-group.vue'),
        ))
        .component('WordpressHeading', defineAsyncComponent(() =>
            import('./wordpress/heading/wordpress-heading.vue'),
        ))
        .component('WordpressHtml', defineAsyncComponent(() =>
            import('./wordpress/html/wordpress-html.vue'),
        ))
        .component('WordpressImage', defineAsyncComponent(() =>
            import('./wordpress/image/wordpress-image.vue'),
        ))
        .component('WordpressList', defineAsyncComponent(() =>
            import('./wordpress/list/wordpress-list.vue'),
        ))
        .component('WordpressListItem', defineAsyncComponent(() =>
            import('./wordpress/list/wordpress-list-item.vue'),
        ))
        .component('WordpressParagraph', defineAsyncComponent(() =>
            import('./wordpress/paragraph/wordpress-paragraph.vue'),
        ))
        .component('WordpressSeparator', defineAsyncComponent(() =>
            import('./wordpress/separator/wordpress-separator.vue'),
        ))
        .component('WordpressSpacer', defineAsyncComponent(() =>
            import('./wordpress/spacer/wordpress-spacer.vue'),
        ))
        .component('WordpressTable', defineAsyncComponent(() =>
            import('./wordpress/table/wordpress-table.vue'),
        ))
        .component('WordpressQuote', defineAsyncComponent(() =>
            import('./wordpress/quote/wordpress-quote.vue'),
        ));
};

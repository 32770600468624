import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePageSettings = defineStore('page-settings', () => {
    const deviceType = ref('desktop');

    const setDeviceType = device => {
        deviceType.value = device;
    };

    return {
        deviceType,
        setDeviceType,
    };
});

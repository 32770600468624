import { addGlobalComponents } from '../../components/default';
import { addGlobalSSRComponents } from '../../components/ssr';

export const addComponents = async (theme, app) => {
    app = await addGlobalComponents(app);

    const themeFile = await import(`../../themes/${theme}/components/default.js`);
    app = themeFile.addComponents(app);

    return app;
};

export const addSSRComponents = async (theme, app) => {
    app = addGlobalSSRComponents(app);

    const themeFile = await import(`../../themes/${theme}/components/ssr.js`);
    app = themeFile.addComponents(app);

    return app;
};

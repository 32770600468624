import { usePageSettings } from '../stores/page';

export default function isDesktop() {
    const wrapper = document.querySelector('body');
    const newSites = ['solarguide', 'silver-blaze', 'boscombe-valley'];
    const isNewSite = newSites.some(site => wrapper.classList.contains(site));
    const isDesktop = window.matchMedia('(min-width: 1024px)');
    const isTablet = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');

    const pageSettings = usePageSettings();
    const { setDeviceType } = pageSettings;

    [isTablet, isDesktop].forEach(device => {
        device.addEventListener('change', () => {
            updateDevice();
        });
    });

    const updateDevice = () => {
        if (isDesktop.matches) {
            setDeviceType('desktop');
            wrapper.setAttribute('data-device', 'desktop');
        } else if (isNewSite && isTablet.matches) {
            setDeviceType('tablet');
            wrapper.setAttribute('data-device', 'tablet');
        } else {
            setDeviceType('mobile');
            wrapper.setAttribute('data-device', 'mobile');
        }
    };

    updateDevice();
}
